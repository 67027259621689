import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import SmartLink from '../components/SmartLink'
import * as styles from '../../src/assets/css/layout.module.css' 

const Home = () => {
  return (
    <section className={styles.page}>
      <Layout>
        <div className={styles.featured}>
          <SmartLink to='/structures/s236'>
            <StaticImage
              src='../../src/images/featured.jpeg'
              alt='Chapel near the Seaside'
              width='325px'
              className={styles.image}
            />
            <p>Featured Building</p>
          </SmartLink>
        </div>
      </Layout>
    </section>
  )
}

export default Home
